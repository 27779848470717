/*global _, $, jQuery, MatchHeightHelper*/
import Helper from './services/Helper/Helper.js';
import Select from './widgets/Select/Select.js';

(function($) {
  $(function() {
    var
      $body = $('body'),
      country = $body.data('country') ? $body.data('country') : 'fr'
    ;

    // Init linkify
    require('linkifyjs/jquery')($, document);
    $('.linkify').linkify();

    // Init Fancybox
    require('fancybox/dist/js/jquery.fancybox.cjs.js')($);
    $('.fancybox').fancybox();

    /*
     * JQUERY VALIDATE.
     * - Javascript form validation before submit.
     */
    if ('undefined' !== typeof $.fn.validate && $.isFunction($.fn.validate)) {
      /*
       * Configure jquery validate for Bootstrap 3.
       */
      $.validator.setDefaults({
        'highlight': function(element) {
          // Add the .has-error class to the form-group.
          $(element).closest('.form-group').addClass('has-error');
        },
        'unhighlight': function(element) {
          // Remove the .has-error class from the form-group.
          $(element).closest('.form-group').removeClass('has-error');
        },
        'errorElement': 'span',
        'errorClass': 'help-block',
        'errorPlacement': function(error, element) {
          // Handle the error placement for checkbox & radio.
          if (element.parent('.input-group').length || 'checkbox' === element.prop('type')  || 'radio' === element.prop('type')) {
            error.appendTo(element.parent());
          } else {
            error.insertAfter(element);
          }
        }
      });

      // Add a polyfill method to test custom regexp against user input.
      $.validator.addMethod('cemail', function(value, element, regexp)  {
        regexp = new RegExp(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i);

        return this.optional(element) || regexp.test(value);
      }, 'Please enter a valid email address');

      $.validator.addMethod('pattern', function(value, element, regexp)  {
        var pattern = new RegExp(regexp);

        return this.optional(element) || pattern.test(value);
      });

      // $.validator.addMethod('alphanumeric', function(value, element) {
      //   var regexp = new RegExp(/^[\u4e00-\u9fff_A-Za-zа-яёЁÀ-Ÿ][\u4e00-\u9fff_a-zа-яёЁÀ-Ÿ0-9\-\s]+$/i);

      //   return regexp.test(value);
      // }, 'Letters, numbers, spaces or dashes only');

      /*
       * Enable jquery-validate for all matching (not just the first) using a each() closure in case there's two form on
       * the same page, eg. sidebar and footer.
       */
      $('.js-contact-form, .js-newsletter-form').each(function (i, e) {
        $(e).validate({
          ignore: [],
          errorElement: 'p',
          errorPlacement: function (error, element) {
            error.attr('aria-describedby', error.attr('id')).insertAfter(element);
          },
          rules: {
            'front_message_type[email]': {
              email: false,
              cemail: true,
            },
            'front_newsletter_type[email]': {
              email: false,
              cemail: true,
            },
            hiddenRecaptcha: {
              required: true,
            }
          }
        });
      });

      $('.max_length_rule').each(function() {
        $(this).rules('add', {
          maxlength: 100,
          alphanumeric: true,
          messages: {
            maxlength: $(this).data('msg-error')
          }
        })
      })

      $('.email_rule').each(function() {
        $(this).rules('add', {
          email: false,
          cemail: true,
          messages: {
            maxlength: $(this).data('msg-error')
          }
        })
      })
    }

    /**
     * MATCH HEIGHT.
     * - Make sure Bootstrap 3 grid does not explode because of various thumbnail/col-* heights.
     */
    $('.match-height .thumbnail.grid').not('.file-icon').matchHeight({
      byRow: false
    });
    $('.js-match-height .card').matchHeight();

    $('.js-match-height .js-match-height-item').matchHeight();

    $('.js-buckle--spokesperson').on('ajaxComplete.jq.buckle', function (event) {
      var
        $element = event.buckle.getElement(),
        $container = event.buckle.getContainer()
      ;
      MatchHeightHelper.update($container[0], true, '.js-match-height .card');
    });

    $('.js-buckle--assets').on('showing.jq.offie-dropdown', '[data-toggle="offie-dropdown"]', function (event) {
      var buckle = $(event.target).closest('.js-buckle--folder').data('buckle');

      // Fetch content of the folder.
      buckle.fetch({});
    });

    $('.js-buckle--assets').on('ajaxComplete.jq.buckle', function (event) {
      var
        $element = event.buckle.getElement(),
        $container = event.buckle.getContainer()
      ;

      // Update the grid.
      MatchHeightHelper.update($container[0], true, '.js-match-height .card');

      // Enable any child buckle component (folders).
      $element.find('[data-toggle="buckle"]').buckle();

      // Enable any offie dropdown component (folders).
      $element.find('[data-toggle="offie-dropdown"]').offieDropdown();
    });

    // Datetimepicker
    var dateTimePickerOptions = {
      'autoclose': true,
      'fontAwesome': true,
      'format': 'yyyy-mm-dd',
      'language': country,
      'minView': 'month',
      'startView': 'year',
    };

    $('#front_search_type_start').datetimepicker(dateTimePickerOptions);
    $('#front_search_type_end').datetimepicker(dateTimePickerOptions);

    require('./modules/share.js');

    Helper
      .convertToArray(document.querySelectorAll('select'))
      .map((select) => new Select(select))
    ;

    $(".datetimepicker.datetimepicker-dropdown-bottom-right").attr('aria-hidden', 'true');

    let countDisplay = 1;
    let navAccessEl = $(".med-nav-access");

    document.addEventListener('keyup', (e) => {
      if (e.key === 'Tab') {
        if (countDisplay == 1) {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          navAccessEl.show().focus();
        } else if (countDisplay > 1 && navAccessEl.is(":visible")) {
          navAccessEl.hide();
        }

        countDisplay ++;
      }
    });

    document.addEventListener('click', (e) => {
      if (navAccessEl.is(":visible")) {
        navAccessEl.hide();
      }

      countDisplay = 1;
    });

    $('.card--spokesperson .card-btn--group').on('click', function() {
      if ($(this).hasClass('offie--active')) {
        $(this).attr('tabindex', -1);
      }
      else {
        $(this).removeAttr('tabindex');
      }

      $('.js-match-height .card--spokesperson').matchHeight();
    });

    $('.card--spokesperson.card--alt .card-img').on('click', function() {
      let ariaExpandedAttr = $(this).attr('aria-expanded') ?? 'false';

      $(this).attr('aria-expanded', ariaExpandedAttr === 'false' ? 'true' : 'false');
    });

    function toggleAriaExpanded(element) {
      let ariaExpandedAttr = element.attr('aria-expanded') ?? 'false';

      element.attr('aria-expanded', ariaExpandedAttr === 'false' ? 'true' : 'false');
    }

    $('.navbar-knob--menu').on('click', function() {
      let labelCloseText = $(this).attr('data-label-close') ?? '';
      let labelOpenText = $(this).attr('data-label-open') ?? '';

      $('.group-icon-right').toggleClass('active');
      $(this).toggleClass('active');
      $('.scroller-mobile-nav').toggleClass('active');
      $('.corporate-item.show-on-mobile').toggleClass('active');

      if ($(this).hasClass('active')) {
        $(this).attr('aria-label', labelCloseText);

        $('body').css('overflow','hidden');
      } else {
        $(this).attr('aria-label', labelOpenText);

        $('body').css('overflow','inherit');
      }
    })

    $('#offie--navigation-0').on('hiding.jq.offie', function() {
      if (!$('.offie-collapse--0').hasClass('offie--active')) {
        $('.navbar-knob.navbar-knob--menu, .corporate-item.show-on-mobile').removeClass('active');
        $('body').css('overflow','inherit');
      }

      if ($('#offie--search > .offie-collapse').hasClass('offie--active')) {
        $('.form-close-submit').trigger('click');
      }

      $('.layout-search .section.section--searchform > .container > .section-block').css('border-top', 'none');

      $('.navbar-element--main.show-on-desktop .nav--channel-1 .nav-item.js-offie button.nav-link[aria-expanded=true]').attr('aria-expanded', 'false');
    });

    $('#offie--navigation-0').on('showing.jq.offie', function(event) {
      if ($('#offie--search > .offie-collapse').hasClass('offie--active')) {
        $('.form-close-submit').trigger('click');
      }

      $('.layout-search .section.section--searchform > .container > .section-block').css('border-top', '2px solid #f2f2f2');
      let $target = event.offie.getTarget();
      $target.height('auto');
    });

    $('.nav-item--search').on('click', function() {
      if ($('#offie--navigation-0 > .offie-collapse').hasClass('offie--active')) {
        $('.navbar-knob--menu').trigger('click');
      }
    });

    $('#offie--search').on('hiding.jq.offie', function() {
      let element = $('.nav-item--search button.nav-link');
      toggleAriaExpanded(element);
    });

    $('#offie--search').on('showing.jq.offie', function() {
      let element = $('.nav-item--search button.nav-link');
      toggleAriaExpanded(element);
    });

    $('.navbar-element--main.show-on-desktop .nav--channel-1 .nav-item.js-offie button.nav-link').on('click', function() {
      if ($(this).hasClass('offie--active')) {
        $(this).attr('aria-expanded', 'true');
      }
      else {
        $(this).attr('aria-expanded', 'false');
      }
    });

    var tempCount = 0;
    let targetNavLinkIcon = $('.navbar-nav > .nav-item > .nav-item-wrap');
    targetNavLinkIcon.on('click', function(e) {
      let $selectorParent =  $(this).parent();
      console.log($selectorParent);
      let $selectorParent2  = $selectorParent.parents().closest('.nav-item');

      if($selectorParent.find('> .nav-item-wrap').hasClass('active')) {
        tempCount --;

        if(tempCount < 0) {
          tempCount = 0;
        }

        if(tempCount === 1) {
          $selectorParent2.find('> .nav-item-wrap').css('display', 'flex');
        }

        $selectorParent.siblings().css('display', 'block');
        $selectorParent.find('> .nav-item-wrap').removeClass('active');
        $selectorParent.find('> .offie-dropdown').removeClass('offie--active');
        $('.navbar-nav.navbar-right, .corporate-item.show-on-mobile').show();
      } else {
        if (tempCount < 1) {
          tempCount ++;
        }

        if (tempCount <= 1) {
          $selectorParent.find('> .offie-dropdown').addClass('offie--active');
          $selectorParent.siblings().css('display', 'none');
          $selectorParent.find('> .nav-item-wrap').addClass('active');
          $('.navbar-nav.navbar-right, .corporate-item.show-on-mobile').hide();
          $('.navbar-element--main').css('border-bottom','none');
        }
      }
    })

    let target_Active = $('.nav > .nav-item.active').parents('.nav-item');
    let is_Active = target_Active.hasClass('active');
    let target_Active_Mobile = target_Active.find('.nav-item-wrap');
    if (!is_Active) {
      target_Active.addClass('active');
    }

    function setProperty() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      window.addEventListener('resize', () => {
        vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      })
    }

    setProperty();

    let orig = $.fn.css;
    $.fn.css = function() {
      var result = orig.apply(this, arguments);
      $(this).trigger('stylechanged');
      return result;
    }

    $('.card').children().on('stylechanged', function () {
      MatchHeightHelper.update('body', true, '.js-match-height .card');
    });

    // Didomi - Display Banner
    let didomiBtn = document.getElementById('didomi-banner-btn') ?? null;
    if (didomiBtn != null) {
      didomiBtn.addEventListener('click', function() {
        window.Didomi?.preferences.show();
      });
    }
  });
}(jQuery));
